.divider {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.divider:before,
.divider:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid #15245e;
    margin: auto;
}

.divider .heading {
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #15245e;
}

.action-container{
    display: flex;
    justify-content: flex-end;
}
.per-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pres-address {
    margin: 10px 0;
}

.mb-10 {
    margin: 10px;
}

.table-data-filtering .full-width {
    padding: 0 10px 0 0;
}

//scss for table need to refactor later
$shadow-white: #e8eafc;
$shadow-grey: #b2b2b21a;
$shadow-dark-grey: #9a9a9a1a;
$small: 576px;
$blue: #536dfe;
$grey: #6e6e6e;
$black-08: rgba(0, 0, 0, 0.08);

mat-card .table-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 16px 16px 32px 16px;
  box-shadow: 0 3px 11px 0 $shadow-white, 0 3px 3px -2px $shadow-grey,
    0 1px 8px 0 $shadow-dark-grey;
  &__header {
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $small) {
      padding: 0 16px;
    }
  }
  td {
    vertical-align: center;
  }
  &.pagination {
    padding-bottom: 30px;
  }
}

.table {
  &__content {
    @media (max-width: $small) {
      overflow-x: scroll;
    }
  }

  &__table {
    width: 100%;
    box-shadow: none;

    .mdc-data-table__header-row{
      background-color: #FAFCFE;
      border-top: 1px solid #d3d0d0;
    }

    tr:nth-child(even) {
      background-color: #FAFCFE;
    }

    tr:last-child {
      border-bottom: 1px solid #C9C9C9;
    }

    @media (max-width: $small) {
      width: 200%;
    }
  }

  &__table-header {
    padding: 1rem;
    max-width: 100%;
    white-space: nowrap;
    background-color: #FAFCFE;
    &.text-center{
      text-align: center;
    }
  }
  &__table-body {
    padding: 0.9rem;
  }
}

.pagination {
  padding: 5px 0 20px 0;
}

.table-top-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.action-button-container{
  display: flex;
  flex: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}

.table-heading{
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 10px;
  h1{
    font-size: 1.2em;
  }
}

.form-control-group {
  display: block;
  width: 95%;
  font-family: "Trebuchet MS";
  padding: 5px 10px;
  .form-control-label {
    font-size: 14px;
    font-weight: bold;
    display: block;
  }
}

.full-width {
  width: 100%;
  padding: 0 10px;
}

.button-height {
  height: 56px;
}

.active {
  padding: 3px;
  color: #198754;
  border-radius: 3px;
  font-weight: bolder;
}

.suspended {
  padding: 3px;
  color: #f6980b;
  border-radius: 3px;
  font-weight: bolder;
}

.cancelled {
  padding: 3px;
  color: #dc3545;
  border-radius: 3px;
  font-weight: bolder;
}

.draft {
  padding: 3px;
  color: #9b59b6;
  border-radius: 3px;
  font-weight: bolder;
}

.inactive {
  padding: 3px;
  color: #dc3545;
  border-radius: 3px;
  font-weight: bolder;
}

.submitted {
  padding: 3px;
  color: #15245e;
  border-radius: 3px;
  font-weight: bolder;
}

.reverted {
  padding: 3px;
  color: #f57328;
  border-radius: 3px;
  font-weight: bolder;
}
.rejected {
  padding: 3px;
  color: #ff1e00;
  border-radius: 3px;
  font-weight: bolder;
}

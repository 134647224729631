@use "./base/index" as *;
@use "./components/index" as *;
@use "./layout/index" as *;
@use "./pages/index" as *;

@use "@angular/material" as mat;
@use "./vendors/univerge-theme" as theme;
@use "./vendors/univerge-typography" as typography;
@use "./utils/helpers" as *;

@include mat.core();

@include mat.all-component-themes(theme.$univerge-portal-theme);

body {
  margin: 0;
  height: 100vh;
  font-family: "Trebuchet MS", Arial, sans-serif;
  font-size: 16px;
  color: #292a1d;
}

// a {
//   text-decoration-color: blue;
//   color: blue;
// }

//need to refactor
.expansion-panel .mat-expansion-panel-header {
  background-color: #1c347f;
  color: #fff;
}

.expansion-panel .mat-expansion-panel-header-title {
  color: white;
}

.expansion-panel .mat-expansion-panel-header-expended {
  margin-bottom: 5px;
}

.expansion-panel .mat-expansion-panel-header:hover {
  background-color: #1c347f;
  color: #fff;
}

.expansion-panel .mat-expansion-indicator {
  color: white;
}

.expansion-panel .mat-expansion-indicator::after {
  color: white;
}

.example-full-width {
  width: 100%;
}

.example-spacer {
  flex: 1 1 auto;
}

.success-snackbar {
  color: white;
  --mdc-snackbar-container-color: #28a745;
  --mat-mdc-snack-bar-button-color: white;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.error-snackbar {
  color: white !important;
  --mdc-snackbar-container-color: #dc3545;
  --mat-mdc-snack-bar-button-color: white;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.mat-mdc-snack-bar-action .mdc-button__label {
  color: #fff !important;
}

.info-snacbar {
  color: white;
  --mdc-snackbar-container-color: #343a40;
  --mat-mdc-snack-bar-button-color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
}

.no-data {
  padding: 20px 0;
  text-align: center;
  font-size: 18px;
}

.mandatory-sign {
  color: red;
}

.custom-mat-field .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after{
  display: none;
}

.mat-mdc-raised-button:not(:disabled){
  color: #FFF !important;
}

::-webkit-scrollbar {
  height: 5px;
  width: 4px;
  background: gray;
}
::-webkit-scrollbar-thumb:horizontal {

  background: #1c347f;;
  background: -webkit-linear-gradient(to right, rgba(1, 99, 113, 1), rgba(0, 175, 199, 1));
  background: linear-gradient(to right,  rgba(1, 99, 113, 1), rgba(0, 175, 199, 1));
  border-radius: 10px;
}
mat-option {
  font-size: 15px !important;
}
mat-select {
  font-size: 15px !important;
}



.submit-icon{
    color: #208C3B;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
}

.revert-icon{
    color: #FD982B;
}

.edit-icon{
    color: #00C531;
}

.view-icon{
    color: #2A52BE;
}
.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #fafcfe;
  border: 1px solid #c9c9c9;
  border-radius: 3px;
 
}
.table {
  border-top: 2px solid rgb(37, 0, 0);
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  &-row {
    display: grid;
    grid-template-columns: 1fr 2fr;

    border-bottom: 1px solid #a3a2a2;
    @media (max-width: 576) {
      grid-template-columns: 1fr;
    }
  }
  &-row-address {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #a3a2a2;
  }
  &-title {
    text-align: right;
    padding: 5px 30px 5px 0;
    background-color: rgba(28, 52, 127, 0.95);
    color: white;
  }
  &-info {
    padding: 5px 0 5px 30px;
    background-color: #fefefe;
  }
  .col-info-1 {
    border-right: 1px solid #a3a2a2;
  }
}

.image-section__nid {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 2rem;
  img {
    width: 70%;
  }
  h1 {
    margin-top: 10px;
    text-align: center;
  }
  .image-section__container {
    text-align: center;
  }
}
.info-container {
  padding: 10px;
}

.address-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  &_container {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576) {
  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
            ""
            "article"
            "section"
            "aside"
            "footer"
            ;
   
  }
}